import requestApi from "lib/services/api/common/boxp_api_client";
import { BASE_API_URL_BOXP } from "lib/constants";
import requestrApi from "lib/services/api/common/boxr_api_client";
import _ from "lodash";

const getQuotation = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation/${id}`,
    method: "GET",
  });
};
const getAllCustomerDetails = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customer/address/all`,
    method: "GET",
    params
  });
};
const getWorkshopOrder = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/workshop_order_generate`,
    method: "POST",
    params,
  });
};

const getBaseStation = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/base-stations`,
    method: "GET",
    params
  });
};
const getCapabilityRating = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/capability_ratings`,
    method: "GET",
    params
  });
};

const acceptanceOrder = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation-acceptance-update/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};
const planningData = (formData, params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/planning`,
    method: "POST",
    data: formData,
    params
  });
};
const createQuotation = (formData) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation`,
    method: "POST",
    data: formData,
  });
};
const getInduction = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/induction/${id}`,
    method: "GET",
  });
};
const getPlanning = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/planning/${id}`,
    method: "GET",
  });
};
const getCustomerList = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/customers-list`,
    method: "GET",
    params
  });
};
const getCustomerListForStock = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/all_customers_list`,
    method: "GET",
    params
  });
};
const getReleaseType = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/fetch_release_types`,
    method: "GET",
    params
  });
};
const getReleaseAuth = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/fetch_release_auth`,
    method: "GET",
    params
  });

};
const getCapability = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/capability`,
    method: "GET",
    params,
  });
};
const getHeaderDetails = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/header/${id}`,
    method: "GET",
  });
};
const getMaintOrderDetails = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/maintenace_order_list/${id}`,
    method: "GET",
  });
};

const getPlatform = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/fetch_platforms`,
    method: "GET",
    params
  });
};
const getSendQuoteStatus = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/send-quote?_method=PUT`,
    method: "POST",
    params,
  });
};

//update handler functions
const updateQuote = (formData, id = null) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/quotation/${id}?_method=PUT`,
    method: "POST",
    data: formData,
  });
};

const getBarcodeData = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/barcode`,
    method: "GET",
    params
  });
};
const getAllActions = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_imp`,
    method: "GET",
    params
  });
};
const getAllActionComments = (params) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/action_comment`,
    method: "GET",
    params
  });
};
const getTabDetails = (id) => {
  return requestApi({
    url: `${BASE_API_URL_BOXP}/order_tab_details/${id}`,
    method: "GET",
  });
};

const updateInductionTabData = (data, id) => {
  return requestApi({
    url: `/induction/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};

const getParams = (param) => {
  let deletedParam = _.omit(param, "id");
  if (param.edit_tab === "") {
    deletedParam = _.omit(param, "edit_tab");
  }
  return deletedParam
}
const updatePlanningTabData = (data, param) => {
  let params = getParams(param)
  return requestApi({
    url: `/planning/${param.id}?_method=PUT`,
    method: "POST",
    data: data,
    params
  });
};

const getParam = (param) => {
  let deletedParam = _.omit(param, "id");
  return deletedParam
}
const updateSaveForLaterData = (data, param) => {
  let params = getParam(param)
  return requestApi({
    url: `/planning/${param.id}?_method=PUT`,
    method: "POST",
    data: data,
    params
  });
};

const updateAwayFromBase = (data, id) => {
  return requestApi({
    url: `/induction-away-from-base-update/${id}?_method=PUT`,
    method: "POST",
    data
  })
}
const updateServiceLifes = (data) => {
  return requestApi({
    url: `/copy_service_life?_method=PUT`,
    method: "POST",
    data
  })
}
const updateCopyAllActions = (data) => {
  return requestApi({
    url: `/copy_all_actions?_method=PUT`,
    method: "POST",
    data
  })
}


const deleteFile = (id) => {
  return requestApi({
    url: `/media/${id}`,
    method: "DELETE"
  })
}

const updateHeader = (data, id) => {
  return requestApi({
    url: `/header/${id}?_method=PUT`,
    method: "POST",
    data
  })
}
const inspectionActionLogic = (data) => {
  return requestApi({
    url: `/inspection_action?_method=PUT`,
    method: "POST",
    data
  })
}
const editButton = (param) => {
  return requestApi({
    url: `/edit_tab?_method=PUT`,
    method: "POST",
    data: param
  })
}


const archiveOrder = (id) => {
  return requestApi({
    url: `/header/${id}`,
    method: 'DELETE',
  })
}

const getActionsDpdOptions = (params) => {
  return requestrApi({
    url: `/workshop_actions/list_action_numbers`,
    method: "GET",
    params
  })
}
const getVehicleList = (params) => {
  return requestrApi({
    url: `/vehicles`,
    method: "GET",
    params
  })
}
const getCustomerAddress = (params) => {
  return requestApi({
    url: `/get_customer_address`,
    method: "GET",
    params
  })
}
const updateCustomerAddress = (data) => {
  return requestApi({
    url: `/customer/address`,
    method: "POST",
    data,
  })
}

const createPartRequest = (data) => {
  return requestrApi({
    url: `/part_requests/operation_part_request`,
    method: "POST",
    data,
  })
}
// This is for Create tab.
const addPart = (formData) => {
  return requestrApi({
    url: "/primary_parts",
    method: "POST",
    data: formData,
  });
};

const createJobRequest = (params) => {
  return requestrApi({
    url: "/part_requests/operation_part_request",
    method: "POST",
    params
  })
}

function fetchDependencies() {
  return requestrApi({
    url: "/primary_parts/fetch_dependencies",
    method: "GET",
  });
}
function getPartNumber(params) {
  return requestApi({
    url: "/get_part_numbers",
    method: "GET",
    params
  });
}

function getRequestPartDetails(params) {
  return requestrApi({
    url: "/part_requests/part_request_details",
    method: "GET",
    params
  });
}

function getConditionedParts(params) {
  return requestrApi({
    url: `/part_conditions/part_condition_info`,
    method: "GET",
    params: params,
  });
}


function editPartWOShopRequest(data) {
  return requestrApi({
    url: `/part_requests/edit_part_request`,
    method: "PUT",
    data,
  });
}

function deleteRequest(data) {
  return requestrApi({
    url: `/part_requests/destroy_request`,
    method: "DELETE",
    data,
  });
}

function getOrderDetails(id) {
  return requestApi({
    url: `/order_tab_details/${id}`,
    method: "GET",
  });
}
function planningClosure(params) {
  return requestApi({
    url: `/planning_closure`,
    method: "GET",
    params
  });
}
function productionStoppage(params) {
  return requestApi({
    url: `/production_stoppage`,
    method: "GET",
    params
  });
}

const unlockPopup = (formData) => {
  return requestApi({
    url: `/unlock_inspection?_method=PUT`,
    method: "POST",
    data: formData,
  });
};
//Release Tab
function getReleaseTabDetails(id) {
  return requestApi({
    url: `/release_details/${id}`,
    method: "GET",

  });
}
const updateReleaseTabData = (data, id) => {
  return requestApi({
    url: `/release_details/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const updateReleaseAwayFromBase = (data, id) => {
  return requestApi({
    url: `/release_details/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const updateScrap = (data, id) => {
  return requestApi({
    url: `/release_details/${id}?_method=PUT`,
    method: "POST",
    data,
  });
};
const createPrimeData = (formData) => {
  return requestApi({
    url: `/add_prime_parts`,
    method: "POST",
    data: formData
  });
};
const updatePrimeData = (formData) => {
  return requestApi({
    url: `/add_prime_parts?_method=PUT`,
    method: "POST",
    data: formData
  });
};

const getReleaseDocument = (params) => {
  return requestApi({
    url: `/release_documents`,
    method: "GET",
    params,
  });
};

const operationalStoppage = (params) => {
  return requestApi({
    url: `/operational_stoppage`,
    method: "GET",
    params,
  });
};
const getStoppedOrders = (params) => {
  return requestApi({
    url: `/operational_stoppage_orders`,
    method: "GET",
    params,
  });
};
const copyPreviousGetPlanningList = (params) => {

  return requestApi({
    url: `/closed_list_view_planning`,
    method: "GET",
    params,
  });
}
const copyPreviousGetActionList = (params) => {
  return requestApi({
    url: `/closed_list_view_action`,
    method: "GET",
    params,
  });
}
const copyPreviousCreateAction = (data) => {
  return requestApi({
    url: `/copy_closed_action`,
    method: "POST",
    data,
  });
}
const copyPlanningFile = (data) => {
  return requestApi({
    url: `/copy_file?_method=PUT`,
    method: "POST",
    data,
  });
}
const copyPreviousCreatePlanningId = (params) => {
  return requestApi({
    url: `/create_planning`,
    method: "POST",
    params,
  });
}
const createPriceBreaks = (formData) => {
  return requestApi({
    url: `/price_breaks`,
    method: "POST",
    data: formData
  });
}
const createProjectParts = (formData) => {
  return requestApi({
    url: `/project_parts`,
    method: "POST",
    data: formData
  });
}
const updateWOProjectParts = (formData) => {
  return requestApi({
    url: `/project_parts?_method=PUT`,
    method: "POST",
    data: formData
  });
}
const updatePLanningProjectParts = (formData) => {
  return requestApi({
    url: `/update_project_part?_method=PUT`,
    method: "POST",
    data: formData
  });
}
const linkUnlinkWOProjectParts = (formData) => {
  return requestApi({
    url: `/link_unlink_project_part?_method=PUT`,
    method: "POST",
    data: formData
  });
}
const createInitialProjectParts = (formData) => {
  return requestApi({
    url: `/store_project_parts`,
    method: "POST",
    data: formData
  });
}
function deleteWOProjectParts(params) {
  return requestApi({
    url: `/project_parts`,
    method: "DELETE",
    params,
  });
}

function deletePlanningProjectParts(params) {
  return requestApi({
    url: `/delete_project_part`,
    method: "DELETE",
    params,
  });
}
const getAllPriceBreaks = (params) => {
  return requestApi({
    url: `/price_breaks`,
    method: "GET",
    params,
  });
};
const getAllProjectParts = (params) => {
  return requestApi({
    url: `/project_parts`,
    method: "GET",
    params,
  });
};

const getProjectPartsDropDown = (params) => {
  return requestApi({
    url: `/get_project_part_numbers`,
    method: "GET",
    params,
  });
};
const getAllPlanningProjectParts = (params) => {
  return requestApi({
    url: `/parts_lists`,
    method: "GET",
    params,
  });
};

const getPlanningProjectPartsDetails = (params) => {
  return requestApi({
    url: `/project_part_details`,
    method: "GET",
    params,
  });
};


const getPriceBreaks = (id) => {
  return requestApi({
    url: `/price_breaks/${id}`,
    method: "GET",
    // params,
  });
};
const getPriceDetails = (params) => {
  return requestApi({
    url: `/unit_price_details`,
    method: "GET",
    params,
  });
};

const editPriceBreaks = (formData, id) => {
  return requestApi({
    url: `/price_breaks/${id}?_method=PUT`,
    method: "POST",
    data: formData
  });
};

const updateAllPriceBreaks = (data) => {
  return requestApi({
    url: `/update_all_price_breaks?_method=PUT`,
    method: "POST",
    data
  });

}

const sendEmailExportRecords = (params) => {
  return requestApi({
    url: `/email_workshop_order_documents`,
    method: "GET",
    params,
  });

}
const getLineFixedFees = (params) => {
  return requestApi({
    url: `/customer/storage_rates/all`,
    method: "GET",
    params,
  })
}
const getCopyInspectionHistory = (params) => {
  return requestApi({
    url: `/action_history`,
    method: "GET",
    params,
  })
}

const addPlanningMaintenanceOrder = (formData) => {
  return requestApi({
    url: `/add_maintenance_production_order`,
    method: "POST",
    data: formData
  });
};


const getTemplateListing = (params) => {
  return requestApi({
    url: `/templates`,
    method: "GET",
    params,
  })
}

const addPlanningTemplate = (formData) => {
  return requestApi({
    url: `/templates`,
    method: "POST",
    data: formData
  });
};
const addPlanningTemplateParts = (formData) => {
  return requestApi({
    url: `/wst_parts`,
    method: "POST",
    data: formData
  });
};
const getTemlatePartsListing = (params) => {
  return requestApi({
    url: `/wst_parts_listing`,
    method: "GET",
    params,
  })
}
const updatePartsTemplate = (data) => {
  return requestApi({
    url: `/edit_parts_quantity?_method=PUT`,
    method: "POST",
    data
  });

}

const updateArchiveTemplate = (data) => {
  return requestApi({
    url: `archive_template?_method=PUT`,
    method: "POST",
    data
  });

}

function deleteTemplateParts(params) {
  return requestApi({
    url: `/delete_wst_parts`,
    method: "DELETE",
    params,
  });
}
const addPlanningTemplateService = (formData) => {
  return requestApi({
    url: `/wst_services`,
    method: "POST",
    data: formData
  });
};
const getTemlateServiceListing = (params) => {
  return requestApi({
    url: `/wst_services_listing`,
    method: "GET",
    params,
  })
}
function deleteTemplateServices(params) {
  return requestApi({
    url: `/delete_wst_service`,
    method: "DELETE",
    params,
  });
}



const addPlanningTemplateEquipment = (formData) => {
  return requestApi({
    url: `/wst_equipments`,
    method: "POST",
    data: formData
  });
};
const getTemlateEquipmentListing = (params) => {
  return requestApi({
    url: `/wst_equipments_listing`,
    method: "GET",
    params,
  })
}
function deleteTemplateEquipment(params) {
  return requestApi({
    url: `/delete_wst_equipment`,
    method: "DELETE",
    params,
  });
}
const copyTemplateServicesFiles = (formData) => {
  return requestApi({
    url: `/copy_service_file?_method=PUT`,
    method: "POST",
    data: formData
  });
};

const updateReorderFunctions = (formData) => {
  return requestApi({
    url: `/reorder_actions?_method=PUT`,
    method: "POST",
    data: formData
  });
};


const getPlanningServiceDetails = (params) => {
  return requestApi({
    url: `/get_workshop_service_life`,
    method: "GET",
    params,
  })
}
const updatePlanningServiceDetails = (formData) => {
  return requestApi({
    url: `/add_service_life`,
    method: "POST",
    data: formData
  });
};
const updatePlanningServiceValue = (formData) => {
  return requestApi({
    url: `/update_service_life_value?_method=PUT`,
    method: "POST",
    data: formData
  });
};
export const MaintenanceServices = {
  getPriceDetails,
  getPriceBreaks,
  editPriceBreaks,
  createPriceBreaks,
  updateAllPriceBreaks,
  getAllPriceBreaks,
  getAllCustomerDetails,
  getStoppedOrders,
  getQuotation,
  getWorkshopOrder,
  getBaseStation,
  acceptanceOrder,
  getInduction,
  getCustomerList,
  getCustomerListForStock,
  getReleaseType,
  getReleaseAuth,
  getCapability,
  getHeaderDetails,
  updateQuote,
  getPlatform,
  getBarcodeData,
  updateInductionTabData,
  getSendQuoteStatus,
  planningData,
  updateAwayFromBase,
  deleteFile,
  createQuotation,
  updatePlanningTabData,
  getPlanning,
  updateHeader,
  getAllActions,
  getAllActionComments,
  getTabDetails,
  archiveOrder,
  getActionsDpdOptions,
  createPartRequest,
  addPart,
  createJobRequest,
  fetchDependencies,
  getRequestPartDetails,
  getConditionedParts,
  editPartWOShopRequest,
  deleteRequest,
  getOrderDetails,
  editButton,
  unlockPopup,
  planningClosure,
  updateSaveForLaterData,
  inspectionActionLogic,
  productionStoppage,
  getMaintOrderDetails,
  getReleaseTabDetails,
  updateReleaseTabData,
  updateReleaseAwayFromBase,
  updateScrap,
  getReleaseDocument,
  operationalStoppage,
  copyPreviousGetPlanningList,
  copyPreviousGetActionList,
  getPartNumber,
  createPrimeData,
  copyPreviousCreateAction,
  copyPlanningFile,
  copyPreviousCreatePlanningId,
  sendEmailExportRecords,
  getLineFixedFees,
  getCapabilityRating,
  getCopyInspectionHistory,
  addPlanningMaintenanceOrder,
  getTemplateListing,
  addPlanningTemplate,
  getTemlatePartsListing,
  addPlanningTemplateParts,
  updatePartsTemplate,
  deleteTemplateParts,
  addPlanningTemplateService,
  getTemlateServiceListing,
  deleteTemplateServices,
  addPlanningTemplateEquipment,
  getTemlateEquipmentListing,
  deleteTemplateEquipment,
  updateArchiveTemplate,
  copyTemplateServicesFiles,
  updateReorderFunctions,
  getVehicleList,
  getCustomerAddress,
  updateCustomerAddress,
  getPlanningServiceDetails,
  updatePlanningServiceDetails,
  updatePlanningServiceValue,
  updateServiceLifes,
  updateCopyAllActions,
  createProjectParts,
  getAllProjectParts,
  getAllPlanningProjectParts,
  getPlanningProjectPartsDetails,
  createInitialProjectParts,
  updateWOProjectParts,
  deleteWOProjectParts,
  linkUnlinkWOProjectParts,
  updatePrimeData,
  updatePLanningProjectParts,
  deletePlanningProjectParts,
  getProjectPartsDropDown
}
