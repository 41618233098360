import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import AddIconBox from "@material-ui/icons/AddBox";
import { getOrganisationId } from "lib/utils/common_utils";
import { Close } from "@material-ui/icons";
import { ICON_COLOUR } from "lib/constants/style_constants";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "750px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden !important"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);

export default function UpdateProjectParts(props) {
    const { handleClose, currentSelection, getPartDetails, projectParts, setCurrentSelectedData, id, primeId, workshopDetails } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)


    const tableOptions = {
        ...options,
        paging: false,
        search: false,


    };
    useEffect(() => {
        if (currentSelection !== undefined) {
            console.log(currentSelection)
            if (currentSelection?.serial_array.length > 0) {
                setData(currentSelection?.serial_array)
            }
            else {
                setData([])
            }

        }
    }, [currentSelection])



    useEffect(() => {
        const value = projectParts.find((item) => item.primary_part_id === currentSelection.primary_part_id)
        // setCurrentSelectedData(value)
        setData(value?.serial_array)


    }, [projectParts])




    const handleDelete = (newData, oldData) => {

        MaintenanceServices.deletePlanningProjectParts({ project_part_id: newData.id }).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Deleted Successfully !",
                    variant: "success",
                });
                getPartDetails()
            }
            else {
                message.showToastMessage({
                    message: res?.error ? res?.error : "Something went wrong. Try again!",
                    variant: "error",
                });
            }

        }).catch((res) => {
            message.showToastMessage({
                message: res?.error ? res?.error : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })


    }

    const handleAdd = (newData, oldData) => {
        const formData = new FormData()

        formData.append("reportable_type", "WorkshopOrder");
        formData.append("primary_part_id", currentSelection?.primary_part_id);
        formData.append("organisation_id", getOrganisationId());
        formData.append("reportable_id", id);
        formData.append("project_primary_part_id", primeId?.id);
        formData.append("quantity", 1);
        setLoading(true);
        MaintenanceServices.createProjectParts(formData)
            .then((res) => {
                if (res?.success) {

                    setLoading(false);
                    message.showToastMessage({
                        message: "Project Parts Added Successfully",
                        variant: "success",
                    });
                    getPartDetails();

                }

                else {

                    setLoading(false);
                    message.showToastMessage({
                        message: res?.error ? res?.error : "Something went wrong. Try again!",
                        variant: "error",
                    });
                    // getList();
                    handleClose();
                }



            })
            .catch((res) => {

                message.showToastMessage({
                    message: res?.error ? res?.error : "Something went wrong. Try again!",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
    }


    const handleUpdate = (newData, oldData) => {
        const params = {
            quantity: newData.quantity,
            serial: newData.serial,
            project_part_id: newData.id
        }
        setLoading(true)
        MaintenanceServices.updatePLanningProjectParts(params).then((res) => {
            getPartDetails()
            message.showToastMessage({
                message: "Updated Successfully !",
                variant: "success",
            });
        }).catch(() => {
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })

    }



    const column = [

        {
            title: "Part #",
            field: "part_number",
            width: '30%',
            editable: "never",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '30%',
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '30%',
            },
        },
        {
            title: "Serial #",
            field: "serial",


        },

        {
            title: "Quantity",
            field: "quantity",

        },
    ];



    return (
        <div>
            <Dialog
                open={true}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>



                    <Grid container xs={12}>
                        <Grid item xs={11}>
                            <Typography
                                variant="body1"
                                color="primary"
                                style={{ marginBottom: "20px", marginLeft: "27px" }}
                                className={classes.popupTitle}
                            >
                                Edit Parts
                            </Typography></Grid>
                        <Grid item xs={1} style={{ marginTop: "-33px", cursor: "pointer", textAlignLast: "right" }}>

                            <Close fontSize="medium" onClick={() => handleClose()} />
                        </Grid>
                    </Grid>


                    <Grid item xs={6} style={{ marginTop: "10px", }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                width: "650px",
                                // minWidth: "500px"
                            }}

                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={loading}
                            actions={workshopDetails?.project_serialised && [
                                {
                                    icon: () => (

                                        <AddIconBox color="secondary" />

                                    ),
                                    tooltip: "Add",
                                    isFreeAction: true,
                                    onClick: (rowData) => {

                                        handleAdd(rowData)
                                    }
                                },
                            ]}
                            editable={
                                {


                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleUpdate(newData, oldData)
                                            resolve();
                                        }),
                                    onRowDelete: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(newData, oldData)
                                            resolve();
                                        }),
                                }
                            }

                        />
                    </Grid>
                </div>
            </Dialog>

        </div>
    )
}
