import React, { useEffect, useState, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MROTextField from "../../../../components/form_components/TextField";
import MROButton from "../../../../components/buttons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PositiveSwitch from "../../../../components/form_components/switch";
import DataService from "lib/services/api";
import { useMessage } from "lib/contexts/message_context";
import { getOrganisationId } from "lib/utils/common_utils";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";

import QuantityCounter from "components/quantity_counter";
import { Autocomplete } from '@material-ui/lab';
import ProjectParts from "../PlanningTab/ProjectParts";
import AutocompletePopper from "components/Popper";
const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
        "& input[name='part_number']": { textTransform: "uppercase" },
        "& input[name='description']": { textTransform: "uppercase" },
    },
    title: {
        fontSize: "20px",
        textAlign: "center"
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "23px 47px",
    },
    actionButtons: {
        paddingBottom: "33px",
        paddingTop: "24px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
    },
    partKey: {
        fontSize: "16px",
        padding: "5px",
    },
    partValue: {
        fontSize: "16px",
        color: "#4D4F5C",
        padding: "5px",
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "453px",
        },
    },

    error: {
        borderColor: "red",
    },

    partText: {
        marginLeft: '14px',
        marginTop: '11px',
        fontWeight: '600'
    },
    disableFieldDesc: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
        textTransform: "uppercase"
    },
}));

function CreateProjectParts(props) {
    const classes = useStyles();
    const message = useMessage();
    const { action, handleClose, getPartDetails, id, tab, primeId, projectParts, quoteStatus, setProjectParts } = props;
    const [primePart, setPrimePart] = useState([])
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [actions, setActions] = useState()

    const [descriptionField, setDescriptionField] = useState(null)


    const [quantity, setQuantity] = useState();
    const [selectedPart, setSelectedPart] = useState({});
    const form = useRef(null);


    useEffect(() => {
        getPartDependencies()
    }, [])

    const getPartDependencies = () => {
        let params = {
            organisation_id: getOrganisationId(),
            active: true,
            repairable: true,
            project_part: false,
            // status: true,
        }


        MaintenanceServices.getPartNumber(params)
            .then((res) => {
                setPrimePart(res.data)
            }).catch(() => {
                console.log("error")
            })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formDataFile = new FormData();
        const optionalFields = ["description"];
        let valid = true;
        let errors = {
            primary_part_id: false,
            quantity: false,

        };

        formData &&
            Object.keys(errors).forEach((keyName) => {
                if (
                    !optionalFields.includes(keyName) &&
                    formData &&
                    !formData.get(keyName)?.length
                ) {
                    errors[keyName] = true;
                    valid = false;
                }
            });
        if (!valid) {
            setError(errors);
            return;
        }
        if (quoteStatus === false) {
            let f_value = projectParts.length > 0 && projectParts.filter((item) => item.primary_part_id === selectedPart?.id)

            let data = {
                "project_primary_part_id": primeId?.id,
                "primary_part_id": selectedPart?.id,
                "part_number": selectedPart?.part_number,
                "description": selectedPart?.description,
                "workshop_standard_pricing_labour": null,
                "workshop_standard_pricing_parts": null,
                "workshop_standard_pricing_services": null,
                "workshop_standard_pricing_equipment": null,
                "workshop_standard_pricing_unit_price": null,
                "workshop_standard_pricing_total": null,
                "quantity": quantity,
                "project_parts_labour": null,
                "project_parts_parts": null,
                "project_parts_services": null,
                "project_parts_equipment": null,
                "project_parts_unit_price": null,
                "project_parts_total": null,
                "std_price": false,
                "serial_numbers": null,
                "item": projectParts.length > 0 ? projectParts.length + 1 : 1,
                "currency": "EUR"
            }
            if (projectParts.length === 25) {
                message.showToastMessage({
                    message: "Max 25 Part #s Allowed!",
                    variant: "error",
                });
                handleClose();
            }

            else if (f_value.length > 0) {
                message.showToastMessage({
                    message: "Part already exists!",
                    variant: "error",
                });
                handleClose();
            }
            else {

                let arr = projectParts.concat(data)
                console.log(arr)
                setProjectParts(arr)
                handleClose();
            }
        } else {


            formData.delete("primary_part_id")
            formData.append("reportable_type", tab === "planning" ? "PrimaryPart" : "WorkshopOrder");
            formData.append("primary_part_id", selectedPart?.id);
            formData.append("organisation_id", getOrganisationId());
            formData.append("reportable_id", id);
            formData.append("project_primary_part_id", tab === "planning" ? id : primeId?.id);

            if (!valid) {
                setError(errors);
                return;
            }
            if (action === "add_parts") {
                setLoading(true);
                MaintenanceServices.createProjectParts(formData)
                    .then((res) => {
                        if (res?.success) {

                            setLoading(false);
                            message.showToastMessage({
                                message: "Project Parts Added Successfully",
                                variant: "success",
                            });
                            getPartDetails();
                            handleClose();
                        }

                        else {

                            setLoading(false);
                            message.showToastMessage({
                                message: res?.error ? res?.error : "Something went wrong. Try again!",
                                variant: "error",
                            });
                            // getList();
                            handleClose();
                        }



                    }).catch((res) => {

                        message.showToastMessage({
                            message: res?.error ? res?.error : "Something went wrong. Try again!",
                            variant: "error",
                        });
                        handleClose();
                    }).finally(() => {
                        setLoading(true)
                    })
            }
        }
    };

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={action}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
                handleSubmit={handleSubmit}
            >
                <DialogTitle>
                    <Typography
                        variant="subtitle2"
                        color="primary"
                        className={classes.title}
                    >
                        Add Part
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form spellcheck="false" onSubmit={handleSubmit}>
                            <Grid
                                style={{ marginBottom: "20px" }}
                                container
                                alignItems={"center"}
                                justify={"flex-start"}
                            >
                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Autocomplete
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        value={selectedPart !== null && selectedPart}
                                        onChange={(event, value) => {
                                            setSelectedPart(value);
                                            setDescriptionField(value?.description)
                                        }}
                                        id="tags-standard"
                                        options={primePart || []}
                                        // loading={loading}
                                        PopperComponent={AutocompletePopper}
                                        getOptionLabel={(option) => option?.part_number || ""}
                                        renderInput={(params) => (
                                            <MROTextField
                                                {...params}
                                                style={{ backgroundColor: "#fff", height: "54px", }}
                                                variant="outlined"
                                                name="primary_part_id"
                                                defaultValue="Select"
                                                placeholder={"Search and Select"}
                                                label="Part Number *"
                                                color={"primary"}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: "search",
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                error={error.primary_part_id}
                                                onSelect={() => {
                                                    // resetErrorsHandler("part_number");

                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <MROTextField
                                        placeholder={"Enter a description - Max 75 characters"}
                                        // name={"item_description"}
                                        label={"Item Description"}
                                        // disabled={!props.permission.write}
                                        InputProps={{
                                            className: classes.disableFieldDesc,
                                        }}
                                        inputProps={{ maxLength: 75, style: { textTransform: "uppercase" } }}
                                        value={descriptionField}
                                        InputLabelProps={{ shrink: true }}
                                    ></MROTextField>
                                </Grid>



                                <Grid item xs={12}>
                                    <QuantityCounter
                                        name={"quantity"}
                                        label={"Quantity *"}
                                        placeholder="Value"
                                        noIncreament
                                        inputProps={{ min: 0, step: 0.5 }}
                                        value={quantity}
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.quantity}
                                        helperText={error?.quantity ? "Please enter Quantity " : ""}
                                        setError={() =>
                                            setError((prevState) => ({
                                                ...prevState,
                                                quantity: false
                                            }))
                                        }
                                        setQty={setQuantity}
                                        qty={quantity}
                                        marginR={"23px"}
                                    />


                                </Grid>

                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    Complete
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>

        </div>
    );
}

export default CreateProjectParts;

