import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MROTextField from "components/form_components/TextField";
import AddIcon from "@material-ui/icons/AddBox";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import PositiveSwitch from "components/form_components/switch";
import MROButton from "components/buttons";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyPreviuos from "./CopyPrevious";
import SearchIcon from "@material-ui/icons/Search";
import AwsDateFormat from "components/form_components/AwsDateFormat";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import UploadButton from "components/form_components/upload_multiple";
import SearchCapability from "./SearchCapability";
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import YesNoField from "components/form_components/YesNoField";
import { validateFormData } from "lib/utils/helperFunctions";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { ConvertYMD, getOrganisationId } from "lib/utils/common_utils";
import PlanningClosure from "./PlanningClosure";
import TechData from "./PlanningTechData"
import CapabilityServices from "lib/services/api/compliance/capability_client";
import CopyNewFunction from "./CopyNewFunction";
import Certification from "./Certification";
import ProjectParts from "./ProjectParts";
import CreateProjectParts from "../QuotePopUp/CreateProjectParts";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    margin: "1% 0",
    display: "flex",
    "& button": {
      marginRight: theme.spacing(2),
      width: "200px",
      "& .MuiButton-label": {
        justifyContent: "space-between",
        width: "150px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
  input: {
    color: "#fff",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  head: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  error: {
    borderColor: "red",
  },
  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
  disableSwitch: {
    pointerEvents: "none",
  },
  disableField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
  message: {
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(1)
  }
}));

function Planning(props) {
  const classes = useStyles();
  const {
    handleClose,
    workshopOrder,
    workshop_id,
    actionStatus,
    setEditTab,
    editTab,
    getMaintenanceOrderList,
    currentSelection,
    readOnly,
    readAndWrite,
    orderStateNum,
    tabStatus,
    closeTabStatus,
    getWorkshopOrderDetails,
    planning_Id,
    actions,
    actionTabId,
    copyTab
  } = props;
  const [expand, setExpand] = useState(true);
  const [visibleIcon, setVisibleIcon] = useState(true);
  const [action, setAction] = useState(null);
  const { state, editButton, setEditButton, setState, openPopup, setOpenPopup, setEditTabStatus, conditionState, setConditionState, copyIdflag, setCopyIdFlag, planningCopyId, setCopyPlanningId, planningNullId, setPlanningNullId } = useContext(WorkshopContext);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(null);
  const [dateFormat, setDateFormat] = useState();
  const message = useContext(ToastMessageContext);
  const [releaseType, setReleaseType] = useState([]);
  const [releaseAuth, setReleaseAuth] = useState([]);
  const [toolRequired, setToolRequired] = useState("");
  const [staffRequired, setStaffRequired] = useState("");
  const [techMaintenance, setTechMaintenance] = useState("");
  const [inspectionAction, setInspectionAction] = useState(actions || actionTabId === 23 ? "no" : "yes");
  const [refData, setRefData] = useState([]);
  const [busy, setBusy] = useState(null);
  const [busyPopup, setBusyPopup] = useState(null);
  const [referenceData, setReferenceData] = useState(null);
  const [planningCreatedStatus, setPlanningCreatedStatus] = useState(false);
  const [closeTab, setCloseTab] = useState(false);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [planningDetails, setPlanningDetails] = useState([]);
  const [filledAllData, setAllFilledData] = useState(false)
  const [isLoadingClosure, setIsLoadingClosure] = useState(false)
  const [closureData, setClosureData] = useState(null)
  const [openClosure, setOpenClosure] = useState(false)
  const [techMaintenanceAvail, setTechnicalMaintenanceAvail] = useState([]);
  const [getData, setGetData] = useState(false)
  const [access, setAccess] = useState({ read: false, write: true });
  const [acceptAll, setAcceptAll] = useState(false);
  const [handleDate, setHandleDate] = useState(false)
  const [disableComplete, setDisableComplete] = useState(false)
  const [updateFlag, setUpdateFlag] = useState(false)
  const [releaseStatus, setReleaseStatus] = useState(false)
  const [techLoading, setTechLoading] = useState(false)
  const [techAction, setTechAction] = useState('')
  const [disabled, setDisabled] = useState(
    (closeTabStatus === 1 && orderStateNum <= 2) || (planningCreatedStatus === false) && props.tabStatus != true ? false : true
  );
  const [fieldsData, setFieldsData] = useState({
    status_work: null,
    release_auth: null,
    release_type_a: null,
    release_type_b: null,
    description: null,
    estimate_release_date: null,
    tooling_required: null,
    staff_required: null,
    tech_maintenance_avail: null,
    tech_maintenance_file: null,
  });

  const handlePopup = () => {
    setEditTabStatus(false)
    if (planning_Id || !planning_Id) {
      setState((ps) => ({ ...ps, warningDialogState: true, isEditing: true }));
    } else if (state.editTabStatus) {
      setState((ps) => ({ ...ps, shouldShowWarning: true, isEditing: true, controlPlanningPop: false }));
    }
  };

  useEffect(() => {
    if (acceptAll) {
      setGetData(true)
      setOpenPopup(true)
      setAllFilledData(false)
    }
  }, [getData, openPopup])

  useEffect(() => {
    if (editButton) {
      handleEditAPiCall();
    }
  }, [editButton]);

  useEffect(() => {
    if (readOnly) {
      setAccess({ read: true, write: false });
      return;
    }

    if (readAndWrite) {
      setAccess({ read: false, write: true });
      return;
    }
  }, [readAndWrite, readOnly]);

  useEffect(() => {
    setState((ps) => ({ ...ps, isEditing: editTab, tab: "planning", warningDialogState: false, controlPlanningPop: true }));

    return () => setState((ps) => ({ ...ps, tab: null }));
  }, [editTab]);

  useEffect(() => {
    if (currentSelection?.planning_id === null) {
      setDisabled(false);
    }
  }, [currentSelection?.planning_id]);

  const onChangeHandler = (e) => {
    handlePopup()
    setReleaseStatus(true)
    const name = e.target.name;
    const value = e.target.value;

    resetErrorsHandler(name);
    setFieldsData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (planningDetails?.capability_id) {
      setErrors((e) => ({ ...e, capability_id: false }));
    }
  }, [planningDetails?.capability_id]);

  useEffect(() => {
    if (actionStatus === "Unlock" || planningCreatedStatus) getPlanningList();
  }, []);

  const planningClosure = async () => {

    setIsLoadingClosure(true)
    const params = {
      workshop_order_id: workshop_id
    }
    await MaintenanceServices.planningClosure(params)
      .then((res) => {
        if (res.success) {
          setClosureData(res.data)
          setGetData(true)
          setDisableComplete(true)
        }
      }).catch((err) => {
        console.log("error", err)
      }).finally(() => {
        setIsLoadingClosure(false)
      })

  }
  console.log("rs is===========================", actionTabId)

  const getPlanningList = async () => {
    if (currentSelection?.planning_id || copyIdflag) {
      setIsGettingDetails(true);
      await MaintenanceServices.getPlanning(copyIdflag ? planningCopyId?.planning_id : currentSelection?.planning_id)
        .then((res) => {
          setPlanningDetails(res.data);
        })
        .catch((err) => {
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        })
        .finally(() => {
          setIsGettingDetails(false);
        });
    }
  };

  // console.log("inspection_action is================", workshop_id, workshopOrder?.id, actionStatus)

  useEffect(() => {
    if (planningDetails?.inspection_action !== undefined) {
      setInspectionAction(planningDetails?.inspection_action ? "yes" : "no")
    }
  }, [planningDetails?.inspection_action])

  //copy previous 

  useEffect(() => {
    if (planningDetails?.inspection_action === null || inspectionAction === null) {
      setInspectionAction(actions ? "no" : "yes")
    }
  }, [planningDetails?.inspection_action])

  useEffect(() => {
    if (actionStatus != "Send Quote ") {
      let data = {};
      Object.keys(planningDetails).forEach((key) => data[key] = planningDetails[key]);

      setFieldsData(data);
      setRefData(planningDetails?.capabilty_id);
      if (planningDetails?.capabilty_ref_data !== undefined) {
        setReferenceData(planningDetails?.capabilty_ref_data);
      }
      setToolRequired(
        planningDetails?.tooling_required === true
          ? "yes"
          : planningDetails?.tooling_required === false
            ? "no"
            : ""
      );
      setTechMaintenance(
        planningDetails?.tech_maintenance_avail === true
          ? "yes"
          : planningDetails?.tech_maintenance_avail === false
            ? "no"
            : ""
      );
      setStaffRequired(
        planningDetails?.staff_required === true
          ? "yes"
          : planningDetails?.staff_required === false
            ? "no"
            : ""
      );
    }
  }, [planningDetails]);

  // console.log("releasee", expand, visibleIcon)

  useEffect(() => {
    if (fieldsData.release_type_b !== null && !releaseStatus) {
      if (planningDetails.release_type_b_name !== "") {
        setExpand(false);
        setVisibleIcon(false)
      }
    }
  }, [planningDetails])

  useEffect(() => {
    getReleaseType();
  }, []);

  const getReleaseType = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getReleaseType(params)
      .then((res) => {
        if (res) {
          setReleaseType(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };

  useEffect(() => {
    getReleaseAuth();
  }, []);

  const getReleaseAuth = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.getReleaseAuth(params)
      .then((res) => {
        if (res) {
          setReleaseAuth(res.data);
        }
        // setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        // setLoading(false);
      });
  };
  const handleClick = () => {
    setAction("save_for_later");
  };

  const params = {
    save_later: 1,
  };

  useEffect(() => {
    if (refData !== [] || refData !== undefined) {
      setErrors((er) => ({ ...er, capability_id: false }));
    }
  }, [refData]);



  useEffect(() => {
    if (refData !== [] || refData !== undefined || refData !== null) {
      setTechLoading(true)
    }

  }, [refData])


  const getPlanningListOnNull = async () => {
    await MaintenanceServices.getPlanning(planningNullId)
      .then((res) => {
        setPlanningDetails(res.data);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setIsGettingDetails(false);
      });

  };

  useEffect(() => {
    if (planningNullId && planning_Id === null) {
      getPlanningListOnNull()
    }
  }, [planningNullId])

  // useEffect(() => {
  //   if (filledAllData) {
  //      planningClosure()
  //     // setAction("complete")
  //   }
  // }, [filledAllData])

  const runInspectionActionLogic = async () => {
    const formData = new FormData()
    formData.append("inspection_action", inspectionAction === "no" ? false : true)
    formData.append("workshop_order_id", workshop_id)
    if (currentSelection.order_state != "Closed") {
      await MaintenanceServices.inspectionActionLogic(formData)
        .then(() => {
          // getWorkshopOrderDetails();
          // getPlanningList();
          // getMaintenanceOrderList();
          // console.log("error")
        }).catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    if (!planning_Id)
      runInspectionActionLogic()
  }, [])

  useEffect(() => {
    if (acceptAll) {
      setUpdateFlag(true)
    }
  }, [acceptAll])

  // console.log("formdata sssssssssssssss==========", sendFormData)

  const handlePlanningFunc = () => {
    setConditionState(false)
    setCloseTab(!closeTab)
    setEditTab(!editTab)
    setPlanningCreatedStatus(true);
  }

  const handleSubmit = async (e) => {
    if (techAction !== "createtechdata" || techAction !== "update_tech") {
      setLoading(action === "save_for_later" ? "save_for_later" : "");
      e.preventDefault();
      const formData = new FormData(document.getElementById("my-form"));
      formData.append(
        "workshop_order_id",
        actionStatus === "Unlock" || 'calendarAction' ? workshop_id : workshopOrder?.id
      );
      formData.delete("capability_id");
      formData.delete("inspection_action");
      formData.append("inspection_action", inspectionAction === "no" ? false : true);
      formData.append("capability_id", refData);
      // if (!handleDate) {
      //   formData.delete("estimate_release_date")
      //   formData.append("estimate_release_date", ConvertYMD(planningDetails?.estimate_release_date))
      // }

      if (action != "save_for_later") {
        const { error, valid } = validateFormData(formData, [
          "inspection_action",
          "tech_data_crs_remarks",
          techMaintenance === "no" ? "tech_maintenance_file" : "",
        ]);
        if (refData === undefined || refData === null) {
          setErrors((er) => ({ ...er, capability_id: true }));
        }

        if (techMaintenance === "no") {
          setLoading(null);
          setErrors((er) => ({ ...er, tech_maintenance_avail: true }));
        }
        if (toolRequired === "no") {
          setLoading(null);
          setErrors((er) => ({ ...er, tooling_required: true }));
        }
        if (staffRequired === "no" && actionTabId === 2) {
          setLoading(null);
          setErrors((er) => ({ ...er, staff_required: true }));
        }

        if (
          (!valid && !disabled) ||
          techMaintenance == "no" ||
          staffRequired == "no" ||
          toolRequired === "no"
        ) {
          setErrors((err) => ({ ...err, ...error }));
          setLoading(null);
          return;
        }

        if (!valid) {
          setLoading(null);
          setErrors(error);
          return;
        }

        if (valid && refData !== undefined && refData !== null) {
          if (action !== "save_for_later" && !acceptAll && !closeTab)

            if (techAction === "createtechdata" || techAction === "update_tech") {
              // console.log("tech=====", techAction, action)
            }
            else {
              console.log("elseee====", techAction, action)
              if (planning_Id === null && planningNullId === null && copyIdflag === false) {
                await MaintenanceServices.planningData(formData, params)
                  .then((res) => {
                    if (res.success) {
                      setPlanningNullId(res.data.id)
                      runInspectionActionLogic()
                      setAction(null)

                    }
                    setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
                    getMaintenanceOrderList();

                  }).catch(() => {

                  })
                planningClosure()
              }
              else if (planning_Id !== null && planningNullId !== null) {
                console.log("tech=====", techAction, action, planning_Id)
                const param = {
                  id: planningNullId ? planningNullId : currentSelection?.planning_id,
                  save_later: 1,
                }
                await MaintenanceServices.updateSaveForLaterData(formData, param)
                  .then((res) => {
                    if (res.success) {
                      runInspectionActionLogic()
                      setAction(null)
                      setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));

                      getMaintenanceOrderList();

                    }
                  }).catch(() => {

                  })
                planningClosure()
              }
              else if (planning_Id === null && planningNullId === null && copyIdflag) {
                console.log("tech=====", techAction, action, planning_Id)
                const param = {
                  id: planningCopyId?.planning_id ? planningCopyId?.planning_id : currentSelection?.planning_id,
                  save_later: 1,
                }
                await MaintenanceServices.updateSaveForLaterData(formData, param)
                  .then((res) => {
                    if (res.success) {
                      runInspectionActionLogic()
                      setAction(null)
                      setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));

                      getMaintenanceOrderList();

                    }
                  }).catch(() => {

                  })
                planningClosure()
              }
              else if (planning_Id !== null && planningNullId === null && copyIdflag === false) {
                console.log("copu=====", techAction, action, planning_Id)
                const param = {
                  id: currentSelection?.planning_id,
                  save_later: 1,
                }
                await MaintenanceServices.updateSaveForLaterData(formData, param)
                  .then((res) => {
                    if (res.success) {
                      runInspectionActionLogic()
                      setAction(null)
                      setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));

                      getMaintenanceOrderList();

                    }
                  }).catch(() => {

                  })
                planningClosure()
              }
              else {
                planningClosure()
              }

            }

        }
      }

      if (!editTab && planning_Id === null && copyIdflag === false) {
        setDisableComplete(false)
        setBusy("loading");
        if ((action === "save_for_later" && refData === undefined) ||
          refData === null) {
          formData.delete("capability_id");
          formData.append("capability_id", "");
        }
        if (action === "save_for_later" && planningNullId === null) {
          await MaintenanceServices.planningData(formData, params)
            .then((res) => {
              if (res.success) {
                runInspectionActionLogic()
                setAction(null)
                setOpenPopup(false)
                setOpenClosure(false)
                setAllFilledData(false)
                message.showToastMessage({
                  message: "Saved Successfully",
                  variant: "success",
                });
              }
              getPlanningList()
              setAction(null)
              setTimeout(() => {
                handleClose();
                getMaintenanceOrderList();
              }, 1000);
            }).catch(() => {
              message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
              });
            }).finally(() => {
              setBusy(null)
              setLoading(null)
            })
        }
        if (action !== "save_for_later" && planning_Id === null && action === "open_popup" && planningNullId === null) {
          setIsLoadingClosure(false)
          setBusyPopup("loading")
          await MaintenanceServices.planningData(
            formData,
            action === "save_for_later" ? params : ""
          )
            .then((res) => {
              runInspectionActionLogic()
              handlePlanningFunc()
              setTimeout(() => {
                handleClose();
                getMaintenanceOrderList();
              }, 1000);
              message.showToastMessage({
                message: "Planning completed Successfully",
                variant: "success",
              });
              setPlanningNullId(null)
              setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
              setAction(null)
            })
            .catch(() => {
              message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
              });
              setLoading(null);
            }).finally(() => {
              setBusyPopup(null)
            })
        }
      }

      if (editTab || planning_Id !== null || planningNullId !== null) {
        setBusy("loading");
        if ((action === "save_for_later" && refData === undefined) ||
          refData === null) {
          formData.delete("capability_id");
          formData.append("capability_id", "");
        }
        // if (action === "open_popup") {
        //   handleClosurePopUpFunction(formData)
        // }
        if (action === "save_for_later") {
          const param = {
            id: planningNullId ? planningNullId : currentSelection?.planning_id,
            save_later: 1,
          }
          await MaintenanceServices.updateSaveForLaterData(formData, param)
            .then((res) => {
              if (res.success) {
                runInspectionActionLogic()
                setAction(null)
                setOpenPopup(false)
                setOpenClosure(false)
                setAllFilledData(false)
                message.showToastMessage({
                  message: "Saved Successfully",
                  variant: "success",
                });
                setPlanningNullId(null)
                setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
                setTimeout(() => {
                  handleClose();
                  getMaintenanceOrderList();
                  setPlanningNullId(null)
                }, 1000);
              }
            }).catch(() => {
              message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
              });
            }).finally(() => {
              setBusy(null)
              setLoading(null)
              setPlanningNullId(null)
            })
        }
        if (action === "open_popup" && (closeTab || updateFlag)) {
          setBusyPopup("loading")
          const param = {
            id: planningNullId ? planningNullId : currentSelection?.planning_id,
            edit_tab: closeTab ? true : ""
          }
          try {
            const response = await MaintenanceServices.updatePlanningTabData(
              formData,
              param
            );
            if (response.success) {
              runInspectionActionLogic();
              handleCloseTab();
              message.showToastMessage({
                message: "Updated Successfully!",
                variant: "success",
              });
              setPlanningNullId(null)
              setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
            } else {
              message.showToastMessage({
                message:
                  response.error.length > 0
                    ? response.error?.join(", ")
                    : "Something went wrong!",
                variant: "error",
              });
            }
            setBusy(null);
            setBusyPopup(null)
          } catch (error) {
            setBusy("error");
            setPlanningNullId(null)
            message.showToastMessage({
              message: "Something went wrong",
              variant: "error",
            });
          }
        }
      }
      //copy previous
      if (action === "save_for_later" && copyIdflag && planning_Id === null && planningNullId === null) {
        const param = {
          id: planningCopyId?.planning_id,
          save_later: 1,
        }
        if ((action === "save_for_later" && refData === undefined) ||
          refData === null) {
          formData.delete("capability_id");
          formData.append("capability_id", "");
        }
        await MaintenanceServices.updateSaveForLaterData(formData, param)
          .then((res) => {
            if (res.success) {
              runInspectionActionLogic()
              setAction(null)
              setOpenPopup(false)
              setOpenClosure(false)
              setAllFilledData(false)
              message.showToastMessage({
                message: "Saved Successfully",
                variant: "success",
              });
              setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
              setCopyIdFlag(false)
              setCopyPlanningId({})
              setTimeout(() => {
                handleClose();
                getMaintenanceOrderList();
              }, 1000);
            }
          }).catch(() => {
            message.showToastMessage({
              message: "Something went wrong",
              variant: "error",
            });
          }).finally(() => {
            setBusy(null)
            setLoading(null)
          })
      }
      if (updateFlag && copyIdflag && planning_Id === null) {
        setBusyPopup("loading")
        const param = {
          id: planningNullId ? planningNullId : planningCopyId?.planning_id,
          // edit_tab: closeTab ? true : ""
        }
        try {
          const response = await MaintenanceServices.updatePlanningTabData(
            formData,
            param
          );
          if (response.success) {
            runInspectionActionLogic();
            handleCloseTab();
            message.showToastMessage({
              message: "Updated Successfully!",
              variant: "success",
            });

            setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
            setCopyIdFlag(false)
            setCopyPlanningId({})
          } else {
            message.showToastMessage({
              message:
                response.error.length > 0
                  ? response.error?.join(", ")
                  : "Something went wrong!",
              variant: "error",
            });
          }
          setBusy(null);
          setBusyPopup(null)
        } catch (error) {
          setBusy("error");
          message.showToastMessage({
            message: "Something went wrong",
            variant: "error",
          });
        }
      }
    }
  };

  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  const dataFromParent = (referData, id) => {
    setRefData(id);
    setReferenceData(referData);
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleToolRequired = (value) => {
    handlePopup();
    if (value) {
      setToolRequired(value);
    }
  };
  const handleStaffRequired = (value) => {
    handlePopup();
    if (value) {
      setStaffRequired(value);
    }
  };
  const handleInspRequired = (value) => {
    handlePopup();
    if (value) {
      setInspectionAction(value);
    }
  };
  const handleTechMaintenance = (value) => {
    handlePopup();
    if (value) {
      setTechMaintenance(value);
    }
  };

  const handleEditAPiCall = async () => {
    setIsGettingDetails(true);
    const param = {
      workshop_order_id: currentSelection?.id,
      tab: "planning",
    };
    await MaintenanceServices.editButton(param)
      .then((res) => {
        setBusy(null);
        if (res.success) {
          getWorkshopOrderDetails();
          setEditButton(!editButton);
          setBusy(null);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setBusy(null);
      })
      .finally(() => {
        setIsGettingDetails(false);
      });
  };

  const handleCloseTab = () => {
    setConditionState(false)
    if (updateFlag) {
      setAction(null)
      setOpenClosure(false)
      setUpdateFlag(false)
      setTimeout(() => {
        handleClose();
        getMaintenanceOrderList();
      }, 1000);
    }
    setCloseTab(!closeTab)
    getPlanningList();
    getWorkshopOrderDetails();
    setEditTab(!editTab);
    setDisabled(!disabled);
  };

  const handleTab = () => {
    setEditTabStatus(true)
    setState((ps) => ({ ...ps, warningDialogState: false, isEditing: false }));
    setEditButton(true);
    setEditTab(!editTab);
    setDisabled(!disabled);
    setCopyIdFlag(false)
  };

  const handleCloseFunction = () => {
    setAction(null)
    setOpenClosure(false)
    setConditionState(false)
    if (acceptAll) {
      setAcceptAll(false)
    }
    setGetData(false)
  }

  //copy previous

  const getCopyPreviousPlanningId = () => {
    setAction("copySection")
    if (planning_Id === null && copyIdflag === false && planningNullId === null) {
      const params = {
        workshop_order_id: workshop_id,
      }

      MaintenanceServices.copyPreviousCreatePlanningId(params)
        .then((res) => {
          setCopyPlanningId(res)
          setCopyIdFlag(true)
        }).catch(() => {
          message.showToastMessage({
            message: "Something went wrong",
            variant: "error",
          });
        }).finally(() => {
          // setBusy(null)
          // setLoading(null)
        })
    }
  }
  return (
    <div>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center">
          <Typography
            style={{
              marginBottom: "12px",
              // fontSize: "20px",
            }}
            color="primary"
          >
            Workscope
          </Typography>
        </Box>
        <MROButton
          disabled={!props?.permission.write}
          type="button"
          variant="contained"
          color="primary"
          onClick={() => { getCopyPreviousPlanningId() }}
          style={{
            display: access.read ? "none" : undefined,
          }}
        >
          {" "}
          <FileCopyIcon />
          &nbsp;&nbsp;Copy Previous
        </MROButton>
      </Box>
      <form noValidate onSubmit={handleSubmit} id="my-form">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MROTextField
              name={"status_work"}
              label={"Status / Work *"}
              placeholder={"Select"}
              select
              requird
              InputProps={{
                className: disabled && classes.disableField,
              }}
              value={fieldsData.status_work}
              InputLabelProps={{ shrink: true }}
              error={errors.status_work}
              onChange={onChangeHandler}
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Inspected / Tested"}>Inspected / Tested</MenuItem>
              <MenuItem value={"Repaired"}>Repaired</MenuItem>
              <MenuItem value={'Overhauled'}>Overhauled</MenuItem>
              <MenuItem value={'Modified'}>Modified</MenuItem>
              <MenuItem value={"New"}>New</MenuItem>
              <MenuItem value={"Prototype"}>Prototype</MenuItem>
              <MenuItem value={"Customer Instruction"}>Customer Instruction</MenuItem>
            </MROTextField>
          </Grid>
          <Grid item xs={3}>
            <MROTextField
              name={"release_auth"}
              label={"Release Auth *"}
              placeholder={"Select"}
              select
              requird
              value={fieldsData.release_auth}
              InputProps={{
                className: disabled && classes.disableField,
              }}
              InputLabelProps={{ shrink: true }}
              error={errors.release_auth}
              onChange={onChangeHandler}
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              {releaseAuth?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.type_name}
                </MenuItem>
              ))}
            </MROTextField>
          </Grid>

          <Grid item xs={4} style={{ alignContent: "center" }}>
            <YesNoField
              disabled={disabled}
              label="Inspection Actions required ?"
              name="inspection_action"
              handleToggle={handleInspRequired}
              value={inspectionAction}
            // NoFieldError={inspectionAction === "no"}
            // error={errors.staff_required}
            // setError={() => resetErrorsHandler("staff_required")}
            />






          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MROTextField
              name={"release_type_a"}
              label={"Release Type 1*"}
              placeholder={"Select"}
              select
              InputProps={{
                className: disabled && classes.disableField,
              }}
              value={fieldsData.release_type_a}
              InputLabelProps={{ shrink: true }}
              error={errors.release_type_a}
              onChange={onChangeHandler}
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              {releaseType?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.type_name}
                </MenuItem>
              ))}
            </MROTextField>
          </Grid>
          {visibleIcon && (
            <Grid
              style={{
                marginLeft: "12px",
                marginTop: "30px",
                display: access.read ? "none" : undefined,
              }}
            >
              <IconButton
                onClick={() => {
                  setExpand(!expand);
                  setVisibleIcon(!visibleIcon);
                }}
                style={{ pointerEvents: !props?.permission.write && "none", color: !props?.permission.write && "#F5F5F5" }}
              >
                <AddIcon color="secondary" />
              </IconButton>
            </Grid>
          )}

          {!visibleIcon && (
            <Grid item xs={3}>
              <MROTextField
                name={"release_type_b"}
                label={"Release Type 2*"}
                placeholder={"Select"}
                select
                InputProps={{
                  className: disabled && classes.disableField,
                }}
                value={fieldsData.release_type_b}
                InputLabelProps={{ shrink: true }}
                error={errors.release_type_b}
                onChange={onChangeHandler}
              >
                <MenuItem value={"select"}>Select</MenuItem>
                {releaseType?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.type_name}
                  </MenuItem>
                ))}
              </MROTextField>
            </Grid>
          )}
          {!expand && (
            <Grid
              style={{
                marginLeft: "12px",
                marginTop: "30px",
                display: access.read ? "none" : undefined,
              }}
            >
              <IconButton
                onClick={() => {
                  setExpand(!expand);
                  setVisibleIcon(!visibleIcon);
                }}
              // style={{visibility: fieldsData.release_type_b ? "hidden" : ""}}
              >
                <RemoveOutlinedIcon color="secondary" />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Grid item container>
          <MROTextField
            required
            name={"description"}
            label={"Description of work"}
            placeholder={"Enter description - max 200 characters"}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 200 }}
            error={errors.description}
            value={fieldsData.description}
            multiline
            InputProps={{
              style: { height: 'max-content', width: "100%" },
              className: disabled && classes.disableField,
            }}
            onChange={onChangeHandler}
          ></MROTextField>
        </Grid>

        <Grid container alignItems={"center"} justify={"flex-start"}>
          <Grid item xs={12}>
            <MROTextField
              name={"tech_data_crs_remarks"}
              label={"Technical Data CRS Remarks"}
              inputProps={{ maxLength: 500 }}
              InputProps={{
                style: { height: 'max-content', width: "100%" },
                className: disabled && classes.disableField,
              }}
              value={fieldsData.tech_data_crs_remarks}
              onChange={onChangeHandler}
              placeholder={
                "Technical Data reference will appear in Block 12 remarks - Max - 500 Characters"
              }
              // InputProps={{ style: { height: 70, width: "100%" } }}
              InputLabelProps={{ shrink: true }}
              multiline
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        {/* 
        <Grid
          container
          spacing={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={4}>
            <AwsDateFormat
              required
              name={"estimate_release_date"}
              type={"date"}
              label={"Estimated Release Date"}
              placeholder={"Date"}
              value={dateFormat}
              disabled={disabled}
              defaultValue={planningDetails?.estimate_release_date}
              formatProps={"YYYY-MM-DD"}
              InputLabelProps={{ shrink: true }}
              error={errors.estimate_release_date}
              onChange={(e, value) => {
                resetErrorsHandler("estimate_release_date");
                setDateFormat(value);
                setHandleDate(true);
                handlePopup();
              }}
            />
          </Grid>
          <Grid
            style={{
              fontStyle: "italic",
              fontSize: "10px",
            }}
          >
            <Typography>
              Check release date aligns with TAT quoted in Quote tab
            </Typography>
          </Grid>
        </Grid> */}
        {(actionTabId === 20 || actionTabId === 24 || actionTabId === 23) && <>
          <Typography style={{ paddingTop: '10px', paddingBottom: '10px', fontSize: '12px' }}>
            Select and Record the applicable In-Service Life values you want to appear in Block 12 remarks
          </Typography>

          <Grid>
            <Certification workshopOrderId={workshop_id} disabled={disabled} tabState={closeTabStatus} />




          </Grid>
        </>}
        {(actionTabId === 20 || actionTabId === 24 || actionTabId === 23) && currentSelection?.project_part && <>
          <Typography style={{ paddingTop: '10px', paddingBottom: '10px' }} color="primary">
            Project Parts
          </Typography>

          <Grid>
            <ProjectParts workshopOrderId={workshop_id} disabled={disabled} tabState={closeTabStatus} currentSelection={currentSelection} permission={props?.permission} tab={"maint"} />




          </Grid>
        </>}



        <Typography color="primary" className={classes.head}>
          Capability Check
        </Typography>

        <Typography>
          Is the work scope within the organisations approval?
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MROTextField
              required
              name={"capability_id"}
              label={"Capability Reference"}
              style={{ pointerEvents: "none", backgroundColor: "#F5F5F5" }}
              placeholder={"Search capability to enter"}
              InputLabelProps={{ shrink: true }}
              value={referenceData}
              error={errors.capability_id}
              onChange={(e) => {
                resetErrorsHandler("capability_id");
                handlePopup();
              }}
            ></MROTextField>
          </Grid>
          <Grid item xs={4} style={{ marginTop: "21px" }}>
            <MROButton
              disabled={!props?.permission.write}
              type="button"
              variant="contained"
              color="primary"
              className={disabled && classes.disableField}
              onClick={() => setAction("searchCapability")}
              style={{ display: access.read ? "none" : undefined }}
            >
              {" "}
              <SearchIcon />
              &nbsp;Search Capability
            </MROButton>
          </Grid>
        </Grid>

        <Grid
          style={{ marginTop: "8px", background: "transparent" }}
          className={disabled || access.read ? classes.disableField : null}
        >
          <YesNoField
            disabled={!props?.permission.write || disabled}
            label="Confirm the organisation currently has availability of the required, Certification Staff, Parts, Tools and Equipment to meet the capability ?*"
            name="tooling_required"
            handleToggle={handleToolRequired}
            value={toolRequired}
            NoFieldError={toolRequired === "no"}
            error={errors.tooling_required}
            setError={() => resetErrorsHandler("tooling_required")}
          />
        </Grid>

        <Grid
          style={{
            marginTop: "12px",
            marginBottom: "10px",
            background: "transparent",
          }}
          className={disabled || access.read ? classes.disableField : null}
        >
          {(actionTabId === 20 || actionTabId === 24) && <YesNoField
            disabled={!props?.permission.write || disabled}
            label="Confirm an AD / SB search has been conducted and any requirements have been planned ?*"
            name="staff_required"
            handleToggle={handleStaffRequired}
            value={staffRequired}
            NoFieldError={staffRequired === "no"}
            error={errors.staff_required}
            setError={() => resetErrorsHandler("staff_required")}
          />}
        </Grid>

        <Typography color="primary" className={classes.head}>
          Technical / Maintenance Data
        </Typography>
        <Typography style={{ fontSize: "15px" }}>
          Attach and or confirm the revision status of ALL technical data linked to the capability
        </Typography>

        <TechData
          refData={refData}
          techLoading={techLoading}
          setTechLoading={setTechLoading}
          techAction={techAction}
          setTechAction={setTechAction}
          planningDetails={planningDetails}
          setAcceptAll={setAcceptAll}
          rprops={props}
          disabled={disabled}
        />
        {/* Old tech data */}

        {/* <Grid
          style={{ marginTop: "8px", background: "transparent" }}
          className={disabled || access.read ? classes.disableField : null}
        >
          <YesNoField
            disabled={!props?.permission.write || disabled}
            label="Technical / Maintenance Data available? *"
            name="tech_maintenance_avail"
            handleToggle={handleTechMaintenance}
            value={techMaintenance}
            NoFieldError={techMaintenance === "no"}
            error={errors.tech_maintenance_avail}
            setError={() => resetErrorsHandler("tech_maintenance_avail")}
          />
        </Grid>

        {techMaintenance === "yes" && (
          <Grid>
            <Grid style={{ marginTop: "15px" }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                }}
              >
                <Grid
                  style={{
                    marginBottom: "20px",
                    // display: access.read ? "none" : undefined,
                  }}
                >
                  <UploadButton
                    readOnly={disabled || !props?.permission.write}
                    visibilityStatus={access.read ? "none" : undefined}
                    multiple={false}
                    noFileExist={"Attached File:"}
                    accept={"application/pdf"}
                    action={"Upload Document"}
                    // value={techMaintenanceAvail}
                    error={errors.tech_maintenance_file}
                    defaultValue={planningDetails?.tech_maintenance_file?.map(
                      (file) => ({
                        name: file.file_name,
                        url: file.path,
                      })
                    )}
                    setFiles={(files) => {
                      setTechnicalMaintenanceAvail(files);
                      // setUploadButtonState(true);
                      resetErrorsHandler("tech_maintenance_file");
                    }}
                    name={"tech_maintenance_file"}
                    label="Attach a PDF of the Technical / Maintenance data you want to be visible in the APP"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )} */}

        <Typography color="primary" className={classes.head}>
          Planning Completion
        </Typography>
        <Typography style={{ fontSize: "15px" }}>
          Prior to completion of planning, ensure initial planned Actions are
          raised in the appropriate Order tabs
        </Typography>

        <div className={"align-right"}>
          {/* {tabStatus && orderStateNum >= 1 && orderStateNum <= 8 && ( */}
          <MROButton
            type="button"
            variant={"contained"}
            style={{
              backgroundColor: "#FFB000",
              color: "white",
              display: tabStatus && orderStateNum >= 1 && orderStateNum <= 8 && props?.permission.write ? "" : "none",
              marginTop: "18px",
            }}
            onClick={() => {
              setTechLoading(true)
              handleTab()

            }}
            startIcon={<img className={classes.svg} src={lock} alt="lock" />}
          >
            Edit Tab
          </MROButton>
          {/* )} */}
          {closeTabStatus === 1 && orderStateNum > 2 && props?.permission.write && (
            <MROButton
              variant={"contained"}
              style={{
                backgroundColor: "red",
                color: "white",
                // display: closeTabStatus === 1 && orderStateNum > 2 ? "" : "none",
                marginTop: "18px",
              }}
              type="submit"
              loading={busy === "loading"}
              startIcon={
                <img className={classes.svg} src={unlock} alt="unlock" />
              }
              onClick={() => {
                setAction("open_popup");
                setCloseTab(!closeTab);
                setTechLoading(true)
              }}
            >
              Close Tab
            </MROButton>
          )}
        </div>


        {/* {action && action === "complete" && */}
        {
          getData && (techAction !== "createtechdata" || techAction !== "update_tech") &&
          < PlanningClosure
            setAction={setAction}
            action={action}
            closureData={closureData}
            acceptAll={acceptAll}
            setAcceptAll={setAcceptAll}
            handleCloseFunction={handleCloseFunction}
            handleSubmit={handleSubmit}
            busyPopup={busyPopup}
            getData={getData}
            setGetData={setGetData}
            handleClose={() => setGetData(false)}
            isLoadingClosure={isLoadingClosure}
          />
        }
        {/* } */}

        {
          closeTabStatus === 1 && orderStateNum <= 2 && props?.permission.write && (
            <Grid item md={12}>
              <div className={"align-right"}>
                <MROButton
                  loading={loading === "save_for_later"}
                  style={{
                    marginRight: "15px",
                    // display: closeTabStatus === 1 && orderStateNum <= 2 ? "" :"none"
                  }}
                  onClick={handleClick}
                  type="submit"
                  variant={"contained"}
                >
                  Save for later
                </MROButton>
                <MROButton
                  disabled={isLoadingClosure || !props?.permission.write}
                  variant={"contained"}
                  type={"submit"}
                  color="primary"
                  name="complete2"
                  value="Submit"
                  onClick={() => {
                    setAction(null)
                  }}
                  style={{
                    // display: access.read ? "none" : undefined,
                  }}
                >
                  Complete
                </MROButton>
              </div>
            </Grid>
          )
        }
      </form >

      {action === "copySection" && (
        <CopyNewFunction handleClose={() => setAction(null)}
          closeTabAction={handleClose}
          currentSelection={currentSelection}
          workshop_id={workshopOrder}
          getWorkshopOrderDetails={getWorkshopOrderDetails}
          getMaintenanceOrder={getMaintenanceOrderList}
          getPlanningList={getPlanningList}
          setPlanningAction={setAction}
          actionTabId={actionTabId}
          planningId={planningCopyId}
          planningNullId={planningNullId}
          planningWholeDetails={planningDetails}
          copyTab={copyTab}
        />
      )
      }
      {
        action === "searchCapability" && (
          <SearchCapability
            handleClose={() => setAction(null)}
            func={dataFromParent}
          />
        )
      }

      {/* {action && action === "complete" && (
        <CautionPopUp
          open={true}
          handleClose={() => setAction(null)}
          cautionMessage={closureData}
          // getAllActionStages={getAllActionStages}
          workshop_id={workshop_id}
          tab={"planning"}
          title="Planning Closure"
          show={false}
          isGettingDetails={getData}
          btnTitle="Complete"
          formDataProps={sendFormData}
        />
      )} */}
    </div >
  );
}

export default Planning;
