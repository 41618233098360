import React, { useContext, useEffect, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import Typography from "@material-ui/core/Typography";
import MROTextField from "components/form_components/TextField";
import FileUpload from "components/form_components/fileUpload";
import QuantityCounter from "components/quantity_counter";
import MROButton from "components/buttons";
import MenuItem from "@material-ui/core/MenuItem";
import YesNoField from "components/form_components/YesNoField";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { validateFormData } from "lib/utils/helperFunctions";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box } from "@material-ui/core";
import { ICON_COLOUR } from "lib/constants/style_constants";
import DeleteAction from "./DeleteAction";
import { BASE_API_URL_BOXP } from "lib/constants";
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";
import { getOrganisationId } from "lib/utils/common_utils";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "3%",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: theme.spacing(5),
    boxShadow: "0px 1px 4px #0000001A",
  },
  actionButtons: {
    paddingTop: "20px",
    paddingBottom: "33px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function EditActionInfo(props) {
  const classes = useStyles();
  const {
    handleClose,
    selectedId,
    closeHandler,
    getAllActions,
    selectedAction,
    getSelectedAction,
    setSelectedRowId, projectParts,
    currentWorkshopDetails
  } = props;
  const message = useContext(ToastMessageContext);
  const [dropDownData, setDropdownData] = useState([]);
  const [errors, setErrors] = useState({});
  const [ataChapter, setAtaChapter] = useState(null);
  const [mechAuth, setMechAuth] = useState(null);
  const [inspAuth, setInspAuth] = useState(null);
  const [busy, setBusy] = useState(null);
  const [action, setAction] = useState(null);
  const [criticalConsideration, setCriticalConsideration] = useState(null);
  const [IndependentInsp, setIndependentInsp] = useState(null);
  const [title, setTitle] = useState(null);
  const [maintFile, setMaintFile] = useState([]);
  const [state, setState] = useState({ man_hours: '' });
  const [detailedDescription, setDetailedDescription] = useState('')
  const [actionCategory, setActionCategory] = useState(null);
  const [selectedProjects, setSelectedProject] = useState({})




  useEffect(() => {
    if (projectParts.length > 0 && selectedAction?.project_part_id !== null) {

      let value = projectParts?.find((item) => item.id === selectedAction?.project_part_id)
      setSelectedProject(value)

    } else if (projectParts.length > 0 && selectedAction?.project_part_id == null) {
      let value = projectParts?.find((item) => item.part_number === "N/A")
      setSelectedProject(value)


    }

  }, [projectParts])





  useEffect(() => {
    if (selectedAction?.ata_chapter_id !== undefined) {
      setAtaChapter(selectedAction?.ata_chapter_id);
    } if (selectedAction?.action_category_id !== undefined) {
      setActionCategory(selectedAction?.action_category_id);
    }
    if (selectedAction?.mech_auth_id !== undefined) {
      setMechAuth(selectedAction?.mech_auth_id);
    }
    if (selectedAction?.insp_auth_id != undefined) {
      setInspAuth(selectedAction?.insp_auth_id);
    }
    setTitle(selectedAction?.title);
    setDetailedDescription(selectedAction?.detailed_description)
    setState({ man_hours: selectedAction?.estimate_man_hours })
    setCriticalConsideration(
      selectedAction?.critical_considerations === true
        ? "yes"
        : selectedAction?.critical_considerations === false
          ? "no"
          : null
    );
    setIndependentInsp(
      selectedAction?.independent_insp_required === true
        ? "yes"
        : selectedAction?.independent_insp_required === false
          ? "no"
          : null
    );
  }, [selectedAction]);

  useEffect(() => {
    getDropdownData();
  }, []);

  const getDropdownData = async () => {
    const params = {
      organisation_id: getOrganisationId()
    }
    await InspectionServices.getAllDropDown(params)
      .then((res) => {
        if (res) {
          setDropdownData(res.data);
        }
      })
      .catch(() => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { error, valid } = validateFormData(formData, [
      "critical_considerations",
      "independent_insp_required",
      "maintenance_file[]",
    ]);
    if (!valid) {
      setBusy(null);
      setErrors(error);
      return;
    }
    const qty = +formData.get("estimate_man_hours");
    if (qty === 0) {
      console.log("qty===", qty)
      setErrors((prevState) => ({
        ...prevState,
        estimate_man_hours_null: "QTY must not be less than zero",
      }))

      return;

    }
    setBusy("loading");
    formData.delete("maintenance_file[]");
    maintFile.forEach((file) => formData.append("maintenance_file[]", file));
    formData.delete("project_part_id")
    if (currentWorkshopDetails?.project_part && selectedProjects?.part_number !== "N/A") {

      formData.append("project_part_id", selectedProjects?.id)

    }
    await InspectionServices.updateAction(formData, selectedId)
      .then((res) => {
        if (res) {
          handleClose();
          getSelectedAction();
          setBusy(null);
          message.showToastMessage({
            message: "Updated successfully!!",
            variant: "success",
          });
        }
      })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      });
    setBusy(null);
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getSelectedAction();
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const handleCriticalConsideration = (value) => {
    if (value) {
      setCriticalConsideration(value);
    }
  };
  const handleIndependentInsp = (value) => {
    if (value) {
      setIndependentInsp(value);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value
    })
  }

  return (
    <div>
      <MROFullScreenRightDrawer open={true}>
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleClose}
                edge="start"
                className={classes.backButton}
                color="inherit"
                aria-label="back"
              >
                <BackIcon />
              </IconButton>
              <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                Edit Action
              </Typography>
            </Box>
            <IconButton onClick={() => setAction("delete")}>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                Delete Item&nbsp;&nbsp;
              </Typography>
              <DeleteIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <form noValidate onSubmit={handleSubmit}>
          <div className={classes.wrapper}>
            <Grid>
              <Typography color="primary" style={{ marginBottom: "10px" }}>
                Action Details
              </Typography>
            </Grid>
            <Grid container spacing={2} >



              <Grid item xs={3}>
                <MROTextField
                  name={"action_category_id"}
                  label={"Action Type *"}
                  placeholder={"Select"}
                  select
                  InputLabelProps={{ shrink: true }}
                  error={errors.action_category_id}
                  value={actionCategory}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setActionCategory(e.target.value);
                  }}
                >
                  {dropDownData?.action_category?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>


              {currentWorkshopDetails?.project_part && <Grid item xs={3}>


                <Autocomplete
                  fullWidth
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions={true}
                  onChange={(e, value) => {
                    setSelectedProject(value)
                    setErrors({ ...errors, project_part_id: false })
                  }}
                  id="tags-user"
                  options={projectParts || []}
                  getOptionLabel={(option) => option.part_number === "N/A" ? option.part_number : `${option.part_number} - ${option.serial}` || ""}
                  value={selectedProjects}
                  renderInput={(params) => (
                    <MROTextField
                      {...params}
                      // variant="standard"
                      label="Project Part  *"
                      name="project_part_id"
                      color={"primary"}
                      InputLabelProps={{ shrink: true }}
                      placeholder={"Search & Select"}
                      error={errors.project_part_id}

                    />
                  )}
                />


              </Grid>}











            </Grid>
            <Grid item xs={12}>
              <MROTextField
                name={"title"}
                label={"Title *"}
                placeholder={actionCategory === 3 ? "The exact AD number and revision that will appear on the release certificate" : actionCategory === 4 ? "The exact SB number and revision that will appear on the release certificate" : actionCategory === 5 ? "The exact SI number and revision that will appear on the release certificate" : actionCategory === 6 ? "The exact SL number and revision that will appear on the release certificate" : "Action Title - Max 75 Characters"}
                inputProps={{ maxLength: 75 }}
                InputLabelProps={{ shrink: true }}
                value={title}
                error={errors.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  resetErrorsHandler(e.target.name);
                }}
              ></MROTextField>
            </Grid>
            <Grid item xs={12}>
              <MROTextField
                name={"detailed_description"}
                label={"Detailed Description *"}
                multiline
                placeholder={"Detailed Action Description - Max 2000 Characters"}
                inputProps={{ maxLength: 2000 }}
                value={detailedDescription}

                InputProps={{
                  style: {
                    height: 'max-content',
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.detailed_description}
                onChange={(e) => {
                  setDetailedDescription(e.target.value)
                  resetErrorsHandler(e.target.name);
                }}
              ></MROTextField>
            </Grid>
            <Grid container spacing={2} >

              <Grid item xs={3}>
                <MROTextField
                  name={"mech_auth_id"}
                  label={"Mech Auth *"}
                  placeholder={"Select"}
                  select
                  InputLabelProps={{ shrink: true }}
                  value={mechAuth}
                  error={errors.mech_auth_id}
                  onChange={(e) => {
                    setMechAuth(e.target.value);
                    resetErrorsHandler(e.target.name);
                  }}
                >
                  {dropDownData?.mech_insp_auth?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.type_name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={3}>
                <MROTextField
                  name={"insp_auth_id"}
                  label={"Insp Auth *"}
                  placeholder={"Select"}
                  select
                  InputLabelProps={{ shrink: true }}
                  error={errors.insp_auth_id}
                  value={inspAuth}
                  onChange={(e) => {
                    setInspAuth(e.target.value);
                    resetErrorsHandler(e.target.name);
                  }}
                >
                  {dropDownData?.mech_insp_auth?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.type_name}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} >
              <Grid item xs={3}>
                <MROTextField
                  name={"ata_chapter_id"}
                  label={"ATA Chapter *"}
                  placeholder={"Select"}
                  select
                  requird
                  InputLabelProps={{ shrink: true }}
                  error={errors.ata_chapter_id}
                  value={ataChapter}
                  onChange={(e) => {
                    resetErrorsHandler(e.target.name);
                    setAtaChapter(e.target.value);
                  }}
                >
                  {dropDownData?.ata_chapters?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.chapter_number}
                    </MenuItem>
                  ))}
                </MROTextField>
              </Grid>
              <Grid item xs={3}>
                <NumericIncrement
                  name="estimate_man_hours"
                  label="Estimate Man-Hrs *"
                  error={errors.estimate_man_hours}
                  value={state.man_hours}
                  placeholder={"Man-Hours required"}
                  onChange={handleChange}
                  noIncreament
                  state={state}
                  setState={setState}
                  inputProps={{ min: 0, step: 0.5 }}
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    (errors["estimate_man_hours_null"] && state.man_hours <= 0) ? <span style={{ color: "red" }}>QTY Required Must Be Greater Than 0</span> : (errors["estimate_man_hours"] && state.man_hours === "") ? <span style={{ color: "red" }}>*Please Enter Estimated Man Hrs</span> : ''
                  }
                  setError={() =>
                    setErrors((prevState) => ({
                      ...prevState,
                      estimate_man_hours: false,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: "8px" }}>
              <YesNoField
                label="Safety critical considerations? (CDCCL, H&S, COSHH etc)"
                name="critical_considerations"
                handleToggle={handleCriticalConsideration}
                value={criticalConsideration}
              />
            </Grid>

            <Grid style={{ marginTop: "8px" }}>
              <YesNoField
                label="Independent Inspection required ?"
                name="independent_insp_required"
                handleToggle={handleIndependentInsp}
                value={IndependentInsp}
              />
            </Grid>

            <Typography
              wrap
              style={{
                marginRight: 30,
                margin: "20px 0px 20px 0px",
                fontSize: "15px",
              }}
            >
              Upload PDF versions of relevant maintenance data you want to be
              visible in the APP
            </Typography>

            <FileUpload
              defaultValue={
                selectedAction?.maintenance !== undefined
                  ? selectedAction?.maintenance.map((item) => ({
                    filename: item?.file_name,
                    url: `${item?.path}`,
                    id: item?.id,
                  }))
                  : ""
              }
              APIUrl={""}
              onDelete={deleteFileHandler}
              isDeleting={busy === "file-deleting"}
              setFiles={(files) => {
                setMaintFile(files);
              }}
              multiple={true}
              width={"50%"}
              showBorderOnNoFiles={true}
              name={"maintenance_file[]"}
              action={"Upload Document"}
            />
          </div>
          <div className={classes.actionButtons}>
            <MROButton variant={"contained"} onClick={handleClose}>
              Cancel
            </MROButton>
            <MROButton
              loading={busy === "loading"}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
            >
              Update
            </MROButton>
          </div>
        </form>
        {action === "delete" && (
          <DeleteAction
            handleClose={() => setAction(null)}
            open={true}
            selectedId={selectedId}
            section={"deleteActionData"}
            closeHandler={closeHandler}
            getAllActions={getAllActions}
            setSelectedRowId={setSelectedRowId}
          />
        )}
      </MROFullScreenRightDrawer>
    </div>
  );
}

export default EditActionInfo;
