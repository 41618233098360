import React, { useEffect, useState, useRef } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MROTextField from "../../../../components/form_components/TextField";
import MROButton from "../../../../components/buttons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PositiveSwitch from "../../../../components/form_components/switch";
import DataService from "lib/services/api";
import { useMessage } from "lib/contexts/message_context";
import ListingService from "../../../../lib/services/api/listing_service";
import ChipInput from "material-ui-chip-input";
import AddIcon from '@material-ui/icons/AddBox';
import { getOrganisationId } from "lib/utils/common_utils";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import AddTags from "./AddTags";

const useStyles = makeStyles((theme) => ({
    formWrapper: {
        background: "#fff",
        width: "100%",
        "& input[name='part_number']": { textTransform: "uppercase" },
        "& input[name='description']": { textTransform: "uppercase" },
    },
    title: {
        fontSize: "20px",
    },
    subTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#4D4F5C",
    },
    card: {
        padding: "10px 25px",
    },
    actionButtons: {
        paddingBottom: "33px",
        textAlign: "right",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },
    partCard: {
        borderRadius: "8px",
        padding: "17px 24px",
    },
    partKey: {
        fontSize: "16px",
        padding: "5px",
    },
    partValue: {
        fontSize: "16px",
        color: "#4D4F5C",
        padding: "5px",
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            maxWidth: "653px",
        },
    },
    purchaseGrid: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
    },
    costData: {
        "& > div": {
            display: "flex",
            "&:not(:last-child)": {
                marginBottom: "10px",
            },
            "& span:first-child": {
                color: "#4D4F5C",
                fontSize: "16px",
                flex: "0 0 30%",
            },
            "& span:last-child": {
                color: "#056191",
                fontSize: "16px",
            },
        },
    },
    cost: {
        "& div:first-child": {
            display: "flex",
            alignItems: "baseline",
            marginBottom: "10px",
            "& > p": {
                fontSize: "20px",
                marginLeft: "13px",
            },
        },
        "& div:last-child": {
            display: "flex",
            alignItems: "center",
            "& > p": {
                margin: "0",
                marginRight: "40px",
            },
        },
    },
    error: {
        borderColor: "red",
    },
    shelfLifeControlled: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // margin: "10px 0 15px",
        margiRight: "-11px",
        "& span:last-child": {
            fontSize: "14px",
        },
    },
    partText: {
        marginLeft: '14px',
        marginTop: '11px',
        fontWeight: '600'
    }
}));

function ManagePrimaryParts(props) {
    const classes = useStyles();
    const message = useMessage();
    const { action, handleClose, getPartDetails, setSelectedPart, setDescription, projectPartState } = props;
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [actions, setActions] = useState()
    const [checked, setChecked] = useState(false);
    const [repairablePart, setRepairablePart] = useState(true);
    const [descriptionField, setDescriptionField] = useState(null)
    const [activeState, setActiveState] = useState(false)
    const [projectState, setProjectState] = useState(false)
    const [partState, setPartState] = useState(false)

    const form = useRef(null);

    const shelfLifeControlledHandler = (e) => {
        setChecked(e.target.checked);
    };
    const handleRepairablePart = (e) => {

        setRepairablePart(e.target.checked)
    }
    const handleActiveState = (e) => {
        setActiveState(e.target.checked)

    }

    useEffect(() => {
        if (projectPartState === true) {
            setProjectState(true)
        }
    }, [projectPartState])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formDataFile = new FormData();
        const optionalFields = ["maintenance_data_ref", "part_supplier_id", "purchase_comments"];
        let valid = true;
        let errors = {
            part_number: false,
            description: false,
            // units_of_measure_id: false,
            // units_issued: false,
        };

        formData &&
            Object.keys(errors).forEach((keyName) => {
                if (
                    !optionalFields.includes(keyName) &&
                    formData &&
                    !formData.get(keyName)?.length
                ) {
                    errors[keyName] = true;
                    valid = false;
                }
            });
        formData.delete("description")
        formData.delete("shelf_life_control")
        formData.delete("repairable")
        formData.delete("active")
        formData.append("description", descriptionField)
        formData.append("shelf_life_control", false);
        formData.append("units_of_measure_id", 1);
        formData.append("units_issued", 1);
        formData.append("repairable", repairablePart);
        formData.append("project_part", projectState);
        formData.append("status", true);
        if (projectState) {
            formData.append("project_serialised", partState);
        }
        formData.append("active", activeState === true ? false : true);
        formData.append("organisation_id", getOrganisationId());

        if (!valid) {
            setError(errors);
            return;
        }
        if (action === "Add") {
            setLoading(true);
            MaintenanceServices.createPrimeData(formData)
                .then((res) => {
                    if (res?.success) {
                        getPartDetails();
                        setDescription(res?.data.description)
                        setSelectedPart({
                            id: res?.data.id,
                            part_number: res?.data.part_number
                        })
                        if (files?.length) {
                            formDataFile.append("id", res?.data.id);
                            formDataFile.append("part_type", "PrimaryPart");
                            DataService.createData(formDataFile, "saveCertificates")
                                .then((resData) => { })
                                .catch((error) => { });
                        }
                        setLoading(false);
                        message.showToastMessage({
                            message: "Parts Added Successfully",
                            variant: "success",
                        });
                        // getList();
                        handleClose();
                    }

                    else {

                        setLoading(false);
                        message.showToastMessage({
                            message: res?.error ? res?.error : "Something went wrong. Try again!",
                            variant: "error",
                        });
                        // getList();
                        handleClose();
                    }



                })
                .catch((err) => {
                    setLoading(false);
                });
        }
        if (action === "editPart") {
            setLoading(true);
            DataService.updateData(formData, "", "primaryParts")
                .then((res) => {
                    if (res?.success) {
                        if (files?.length) {
                            formDataFile.append("id", res?.data?.id);
                            DataService.createData(formDataFile, "saveCertificates")
                                .then((resData) => { })
                                .catch((error) => { });
                        }
                        setLoading(true);
                        message.showToastMessage({
                            message: "Parts Updated Successfully",
                            variant: "success",
                        });
                        // getList();
                        handleClose();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    // useEffect(() => {
    //     if (currentSelection?.id) {
    //       fetchCertificates();
    //     } else {
    //       setDrawerState(true);
    //     }
    //   }, [currentSelection?.id]);

    //   const fetchCertificates = () => {
    //     const params = {
    //       id: currentSelection?.id,
    //     };
    //     ListingService.getData(params, "getCertificates")
    //       .then((res) => {
    //         if (res?.success) {
    //           setCertificatesData(res?.data);
    //           setDrawerState(true);
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("Certificates error =>", error);
    //       });
    //   };

    const handleDeleteCertificates = (id) => {
        DataService.deleteData("", "removeCertificates", { id: id })
            .then((res) => {
                if (res?.success) {
                    message.showToastMessage({
                        message: "Removed successfully",
                        variant: "success"
                    })
                }
            })
            .catch((error) => {
                console.log("Delete certificate delete error =>", error);
            });
    };

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={action}
                className={classes.modal}
                onClose={handleClose}
                aria-labelledby="base-station-manage"
                handleSubmit={handleSubmit}
            >
                <DialogTitle>
                    <Typography
                        variant="subtitle2"
                        color="primary"
                        className={classes.title}
                    >
                        {" "}
                        {action} Workshop Part
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.card}>
                    <div className={classes.formWrapper}>
                        <form spellcheck="false" ref={form} onSubmit={handleSubmit}>
                            <Grid
                                style={{ marginBottom: "20px" }}
                                container
                                spacing={5}
                                alignItems={"center"}
                                justify={"flex-start"}
                            >
                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <MROTextField
                                        name={"part_number"}
                                        label={"Part Number *"}
                                        InputLabelProps={{ shrink: true }}
                                        autoFocus={error.part_number}
                                        inputProps={{ maxLength: 40 }}
                                        // defaultValue={currentSelection.part_number}
                                        error={error.part_number}
                                        helperText={
                                            error.part_number ? "*please enter partNumber" : null
                                        }
                                        onChange={(e) => {
                                            setError({ ...error, part_number: false });
                                        }}
                                    />
                                </Grid>

                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <MROTextField
                                        name={"description"}
                                        label={"Description *"}
                                        InputLabelProps={{ shrink: true }}
                                        autoFocus={error.description}
                                        error={error.description}
                                        value={descriptionField}
                                        placeholder={"Enter a description - Max 75 characters"}
                                        inputProps={{ maxLength: 75, style: { textTransform: "uppercase" } }}
                                        helperText={
                                            error.description ? "*please enter description" : null
                                        }
                                        onChange={(e) => {
                                            setError({ ...error, description: false });
                                            setDescriptionField(e.target.value)
                                        }}
                                    // defaultValue={currentSelection.description}
                                    />
                                </Grid>
                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Grid xs={6}>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={repairablePart}
                                                    onChange={(e) => handleRepairablePart(e)}
                                                    // name="Repairable part?"
                                                    color="success"
                                                />
                                            }
                                            label="Repairable part?"
                                            labelPlacement="start"
                                            disabled={true}
                                            // name={"repairable_parts"}
                                            // defaultChecked={currentSelection.shelf_life_control}
                                            className={classes.shelfLifeControlled}
                                        />
                                    </Grid>


                                </Grid>
                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Grid xs={6}>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={activeState}
                                                    onChange={(e) => handleActiveState(e)}
                                                    color="success"
                                                />
                                            }
                                            disabled={true}
                                            label="Hide/Disable in Store/Stock listing?"
                                            labelPlacement="start"
                                            name={"active"}
                                            className={classes.shelfLifeControlled}
                                        />
                                    </Grid>
                                </Grid> <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Grid xs={6}>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={projectState}
                                                    onChange={(e) => setProjectState(e.target.checked)}
                                                    // name="Hide/Disable in Store/Stock listing?"
                                                    color="success"
                                                />
                                            }

                                            label="Workshop Project Part?"
                                            labelPlacement="start"
                                            name={"active"}
                                            // defaultChecked={currentSelection.shelf_life_control}
                                            className={classes.shelfLifeControlled}
                                        />
                                    </Grid>
                                </Grid>
                                {projectState && <Grid className={classes.purchaseGrid} item xs={12}>
                                    <Grid xs={6}>
                                        <FormControlLabel
                                            control={
                                                <PositiveSwitch
                                                    checked={partState}
                                                    onChange={(e) => setPartState(e.target.checked)}
                                                    // name="Hide/Disable in Store/Stock listing?"
                                                    color="success"
                                                />
                                            }
                                            // disabled={true}
                                            label="Project Parts Individually Serialised?"
                                            labelPlacement="start"
                                            name={"active"}
                                            // defaultChecked={currentSelection.shelf_life_control}
                                            className={classes.shelfLifeControlled}
                                        />
                                    </Grid>
                                </Grid>}
                                {/* <Grid className={classes.purchaseGrid} item xs={6}>
                                    <MROTextField
                                        name={"units_of_measure_id"}
                                        label={"Unit of Measure *"}
                                        select
                                        InputLabelProps={{ shrink: true }}
                                        autoFocus={error.units_of_measure_id}
                                        error={error.units_of_measure_id}
                                        helperText={
                                            error.units_of_measure_id
                                                ? "*please select unit of Measure"
                                                : null
                                        }
                                        onChange={(e) => {
                                            setError({ ...error, units_of_measure_id: false });
                                        }}
                                    defaultValue={currentSelection.units_of_measure_id}
                                    >
                                        <MenuItem value={undefined}>
                                            Select Unit of Measure
                                        </MenuItem>
                                        {unitOfMeasures.map((uom) => (
                                            <MenuItem
                                                key={uom.units_of_measure_id}
                                                value={uom.units_of_measure_id}
                                            >
                                                {uom.unit_name}
                                            </MenuItem>
                                        ))}
                                    </MROTextField>
                                </Grid>
                                <Grid className={classes.purchaseGrid} item xs={6}>
                                    <MROTextField
                                        name={"units_issued"}
                                        label={"Units Issued *"}
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        autoFocus={error.units_issued}
                                        error={error.units_issued}
                                        helperText={
                                            error.units_issued ? "*please enter units Issued" : null
                                        }
                                        onChange={(e) => {
                                            setError({ ...error, units_issued: false });
                                        }}
                                    defaultValue={currentSelection?.units_issued}
                                    ></MROTextField>
                                </Grid>
                                <Grid className={classes.purchaseGrid} item xs={12}>
                                    <MROTextField
                                        name={"maintenance_data_ref"}
                                        label={"Maint Data Ref"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 50 }}
                                    defaultValue={currentSelection?.maint_data_ref}
                                    />
                                </Grid>
                              
                                <Grid container xs={12} style={{ padding: '17px' }}>

                                    <Grid item xs={6} container direction="row" >
                                        <Grid className={classes.purchaseGrid} item xs={12}>
                                            <MROTextField
                                                name={"part_supplier_id"}
                                                label={"Supplier"}
                                                InputLabelProps={{ shrink: true }}
                                                select
                                            defaultValue={currentSelection?.part_supplier_id}
                                            >
                                                <MenuItem value={undefined}>Supplier</MenuItem>
                                                {suppliers.map((supplier) => (
                                                    <MenuItem value={supplier.part_supplier_id}>
                                                        {supplier.name}
                                                    </MenuItem>
                                                ))}
                                            </MROTextField>
                                        </Grid>
                                        <Grid className={classes.purchaseGrid} item xs={12}>
                                            <MROTextField
                                                name={"purchase_comments"}
                                                label={"Comments"}
                                                multiline
                                                inputProps={{ maxLength: 100 }}
                                                placeholder={"Purchasing comments - max 100 characters"}
                                                InputProps={{ style: { height: 80, width: "100%" } }}
                                                InputLabelProps={{ shrink: true }}
                                            defaultValue={currentSelection?.purchase_comments}
                                            />
                                        </Grid>


                                    </Grid>


                                    <Grid item xs={6} container direction="row" style={{ paddingLeft: '15px' }}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" className={classes.partText}>Part Options</Typography>

                                        </Grid>
                                        <Grid item xs={12}>

                                            <FormControlLabel
                                                control={
                                                    <PositiveSwitch
                                                        checked={checked}
                                                        onChange={(e) => shelfLifeControlledHandler(e)}
                                                        name="shelf_life_control"
                                                        color="success"
                                                    />
                                                }
                                                label="Shelf-life controlled part?"
                                                labelPlacement="start"
                                                name={"shelf_life_control"}
                                                defaultChecked={currentSelection.shelf_life_control}
                                                className={classes.shelfLifeControlled}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <PositiveSwitch
                                                        checked={repairablePart}
                                                        onChange={(e) => handleRepairablePart(e)}
                                                        name="Repairable part?"
                                                        color="success"
                                                    />
                                                }
                                                label="Repairable part?"
                                                labelPlacement="start"
                                                name={"repairable_parts"}
                                                defaultChecked={currentSelection.shelf_life_control}
                                                className={classes.shelfLifeControlled}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <PositiveSwitch
                                                        checked={activeState}
                                                        onChange={(e) => handleActiveState(e)}
                                                        name="Hide/Disable in Store/Stock listing?"
                                                        color="success"
                                                    />
                                                }
                                                label="Hide/Disable in Store/Stock listing?"
                                                labelPlacement="start"
                                                name={"active"}
                                                defaultChecked={currentSelection.shelf_life_control}
                                                className={classes.shelfLifeControlled}
                                            /></Grid>
                                    </Grid> 
                                    </Grid>
                                    */}

                                {/* <Grid className={classes.purchaseGrid} item xs={6} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h6">Part Tags</Typography>
                                    <IconButton onClick={() => setActions("add_tag")}>
                                        <AddIcon color="primary" />
                                    </IconButton>
                                </Grid> */}
                                {/* <Grid className={classes.purchaseGrid} item xs={6}>
                                    <ChipInput
                                        defaultValue={action === "update" && currentSelection?.part_number?.split(',')}
                                        onChange={(chips) => {
                                          setNewPart(chips);
                                          setPart(chips.join());
                                        }}
                                        label="Applicable to Part Numbers"
                                        onKeyDown={handleKeyDown}
                                        onKeyUp={handleKeyUp}
                                        name={"order_emails"}
                                        fullWidth
                                        type="email"
                                        select
                                        classes={{
                                            input: classes.chipInput,
                                        }}
                                        helperText={"Hit enter after typing the tag title"}
                                    />
                                </Grid> */}
                                {/* <Grid className={classes.purchaseGrid} item xs={12}>
                                    <FileUpload
                                        onDelete={handleDeleteCertificates}
                                        // defaultValue={certificatesData}
                                        label={"Material Data"}
                                        setFiles={setFiles}
                                        multiple={true}
                                        name={"documents[]"}
                                        action={"Upload Document"}
                                    error={error?.material_data}
                                    setError={() =>
                                      setError((err) => ({ ...err, material_data: false }))
                                    }
                                    />
                                </Grid> */}
                            </Grid>
                            <div className={classes.actionButtons}>
                                <MROButton
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={loading}
                                    type="submit"
                                    variant={"contained"}
                                    color="primary"
                                >
                                    {action === "Add" ? "Complete" : "Edit"}
                                </MROButton>
                            </div>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
            {actions && actions === "add_tag" &&
                <AddTags
                    handleClose={() => setActions(null)}
                    open={actions}
                />
            }
        </div>
    );
}

export default ManagePrimaryParts;
