import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { ToastMessageContext } from "lib/contexts/message_context";
import React, { useContext, useEffect, useState } from "react";

const useGetProjectParts = (params) => {


    const [projectParts, setProjectParts] = useState([])
    const message = useContext(ToastMessageContext);
    const [filetr, setFilters] = useState([])

    const getProjectParts = async () => {


        await MaintenanceServices.getProjectPartsDropDown(params)
            .then((res) => {
                if (res.success) {
                    const obj = {
                        part_number: "N/A",
                        id: null,
                        serial: null
                    }

                    let data = [obj, ...res.data]
                    setProjectParts(data);
                }
                // setLoading(false);
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                // setLoading(false);
            }).finally(() => {

            })
    };
    useEffect(() => {
        getProjectParts();
    }, []);

    return projectParts
}

export default useGetProjectParts

